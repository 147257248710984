// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GooglePlace-module__input--GebOk{outline:none}.GooglePlace-module__branding--vqzkc{display:block;margin:4px 4px 2px;font-size:8px;font-weight:300;text-align:right}.GooglePlace-module__branding--vqzkc svg{height:10px;width:auto}`, "",{"version":3,"sources":["webpack://./../ui/components/Form/UI/GooglePlace/GooglePlace.module.scss"],"names":[],"mappings":"AAEA,kCACE,YAAA,CAGF,qCACE,aAAA,CACA,kBAAA,CACA,aAAA,CACA,eAAA,CACA,gBAAA,CAEA,yCACE,WAAA,CACA,UAAA","sourcesContent":["@import '../../../CompactDesign/mixins';\n\n.input {\n  outline: none;\n}\n\n.branding {\n  display: block;\n  margin: 4px 4px 2px;\n  font-size: 8px;\n  font-weight: 300;\n  text-align: right;\n\n  svg {\n    height: 10px;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `GooglePlace-module__input--GebOk`,
	"branding": `GooglePlace-module__branding--vqzkc`
};
export default ___CSS_LOADER_EXPORT___;
