import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'

import { FullDefunctJSON } from '../../models/Defunct/Full'

export const Defuncts = {
  show: authenticate(
    (dealId: number): ApiRequestDescriptor<FullDefunctJSON> =>
      get(`/api/v1/deals/${dealId}/defunct`),
  ),
}
