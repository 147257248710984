import { FormattedMessage, useIntl } from 'react-intl';

import { DealState } from '@advitam/api/models/Deal/State';
import Check from '@advitam/ui/images/icons/check.svg';
import { Tooltip } from '@advitam/ui';
import { Summary } from 'models/Deal/Summary';
import { safeFormatMessage } from 'utils/functions.typed';

import messages from '../../messages';
import style from './State.module.scss';

interface StateProps {
  deal: Summary;
}

export default function State({ deal }: StateProps): JSX.Element {
  const intl = useIntl();

  const classes = [style.container, style[deal.state]];

  const statusMessage = messages[deal.state];
  const TooltipMessage = (
    <FormattedMessage
      id={messages.status.id}
      values={{
        status: <FormattedMessage id={statusMessage.id} />,
        paid: deal.paid,
        ko: deal.state === DealState.KO,
        ko_reason: deal.ko_reason
          ? safeFormatMessage(intl, messages, deal.ko_reason)
          : null,
      }}
    />
  );

  return (
    <Tooltip
      contentClassName={classes.join(' ')}
      content={TooltipMessage}
      disabled={!TooltipMessage}
    >
      {deal.paid && <Check />}
    </Tooltip>
  );
}
