import PropTypes from 'prop-types';

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';

import H2 from 'components/H2';
import { TextInput } from 'components/Input';
import SelectAddress from 'components/SelectAddress';
import OtherFuneralServices from 'components/OtherFuneralServices';
import messagesCollapse from 'components/Collapse/messages';
import messagesCrud from 'messages/crud';
import { makeSelectIsManualMode } from 'containers/Deal/selectors';

import { cloneDeep } from 'lodash';
import {
  setOtherFuneralInfo,
  addOtherFuneralTvaService,
  deleteOtherFuneralTvaService,
  updateTvaTotalPrice,
} from './actions';
import {
  makeSelectOtherFuneral,
  makeSelectOtherFuneralServiceByTva,
  makeSelectOtherFuneralPriceByTva,
} from './selectors';
import messages from './messages';
import style from './OtherFuneralsCompany.module.scss';

class OtherFuneralCompany extends PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor() {
    super();
    this.state = { isOpen: false };
  }

  handleCollapse = () =>
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  /**
   * Function to update contact of other funeral company
   *
   * @param {string} name key to update
   * @param {string} value value to update
   */
  handleOtherFuneralContact = (name, value) => {
    const { otherFuneral, updateOtherFuneralInfo } = this.props;
    updateOtherFuneralInfo({
      ...otherFuneral,
      contact: {
        ...otherFuneral.contact,
        [name]: value,
      },
    });
  };

  /**
   * Check if phone is valid
   *
   * @param {string} value phone number
   */
  isPhoneValid = value => {
    const { intl } = this.props;
    return (
      (value &&
        !isValidPhoneNumber(value) &&
        intl.formatMessage(messagesCrud.errorPhoneFormat)) ||
      undefined
    );
  };

  render() {
    const { isOpen } = this.state;
    const {
      updateOtherFuneralInfo,
      otherFuneral,
      updateTvaTotalPrice: updateTvaTotalPriceProps,
      selectTvaPrice,
      selectTvaService,
      addService,
      removeService,
      isManualMode,
    } = this.props;

    const faxNumber = (otherFuneral.contact && otherFuneral.contact.fax) || '';
    const isFaxValid = this.isPhoneValid(faxNumber);

    const phone = (otherFuneral.contact && otherFuneral.contact.phone) || '';
    const isPhone1Valid = this.isPhoneValid(phone);

    const phone2 = (otherFuneral.contact && otherFuneral.contact.phone_2) || '';
    const isPhone2Valid = this.isPhoneValid(phone2);

    return (
      <section id="otherCompany" className="otherFuneralCompany">
        <button
          type="button"
          onClick={this.handleCollapse}
          className={style.header_button}
        >
          <H2 inline>
            <FormattedMessage {...messages.title} />
          </H2>
          <FormattedMessage
            id={messagesCollapse.collapseHint.id}
            tagName="span"
          />
        </button>
        <div className={`${style.wrapper} ${isOpen ? style.open : ''}`}>
          <div className="otherFuneralCompany__container">
            <div className="otherFuneralCompany__container__form col-6">
              <div className="col-12">
                <div className="col-6">
                  <FormattedMessage {...messages.funeralCompanyName} />
                </div>
                <div className="col-6">
                  <TextInput
                    onChange={({ target: { value } }) =>
                      updateOtherFuneralInfo({
                        ...otherFuneral,
                        name: value,
                      })
                    }
                    name="name"
                    value={otherFuneral.name || ''}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="col-6">
                  <FormattedMessage {...messages.funeralCompanyPhone1} />
                </div>
                <div className="col-6">
                  <PhoneInput
                    onChange={value =>
                      this.handleOtherFuneralContact('phone', value)
                    }
                    value={phone}
                    error={isPhone1Valid}
                    initialValueFormat="national"
                    defaultCountry="FR"
                    labels={fr}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="col-6">
                  <FormattedMessage {...messages.funeralCompanyPhone2} />
                </div>
                <div className="col-6">
                  <PhoneInput
                    onChange={value =>
                      this.handleOtherFuneralContact('phone_2', value)
                    }
                    value={phone2}
                    error={isPhone2Valid}
                    initialValueFormat="national"
                    defaultCountry="FR"
                    labels={fr}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="col-6">
                  <FormattedMessage {...messages.funeralCompanyFax} />
                </div>
                <div className="col-6">
                  <PhoneInput
                    onChange={value =>
                      this.handleOtherFuneralContact('fax', value)
                    }
                    value={faxNumber}
                    error={isFaxValid}
                    initialValueFormat="national"
                    defaultCountry="FR"
                    labels={fr}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="col-6">
                  <FormattedMessage {...messages.funeralCompanyAddress} />
                </div>
                <div className="col-6">
                  <SelectAddress
                    address={otherFuneral.address}
                    onChange={address =>
                      updateOtherFuneralInfo({
                        ...otherFuneral,
                        address,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <OtherFuneralServices
                updateTvaTotalPrice={updateTvaTotalPriceProps}
                selectTvaPrice={selectTvaPrice}
                selectTvaService={selectTvaService}
                addService={addService}
                removeService={removeService}
                isManualMode={isManualMode}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

OtherFuneralCompany.propTypes = {
  otherFuneral: PropTypes.object,
  updateOtherFuneralInfo: PropTypes.func.isRequired,
  addService: PropTypes.func.isRequired,
  selectTvaPrice: PropTypes.func.isRequired,
  selectTvaService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  updateTvaTotalPrice: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  isManualMode: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  otherFuneral: makeSelectOtherFuneral(),
  selectTvaService: makeSelectOtherFuneralServiceByTva(),
  selectTvaPrice: makeSelectOtherFuneralPriceByTva(),
  isManualMode: makeSelectIsManualMode(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateOtherFuneralInfo: update =>
      dispatch(setOtherFuneralInfo({ otherFuneral: { ...update } })),
    dispatch,
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { otherFuneral: frozenOtherFuneral } = stateProps;

  const otherFuneral = cloneDeep(frozenOtherFuneral);

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    removeService: tvaIndice => (
      serviceType,
      serviceDescription,
      indexService,
    ) =>
      dispatch(
        deleteOtherFuneralTvaService(
          serviceType,
          serviceDescription,
          tvaIndice,
          indexService,
          otherFuneral,
        ),
      ),
    addService: tvaIndice => (serviceType, serviceDescription) =>
      dispatch(
        addOtherFuneralTvaService(
          serviceType,
          serviceDescription,
          tvaIndice,
          otherFuneral,
        ),
      ),
    updateTvaTotalPrice: (tvaIndice, value) =>
      dispatch(updateTvaTotalPrice(tvaIndice, value, otherFuneral)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default compose(withConnect, injectIntl)(OtherFuneralCompany);
